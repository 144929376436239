import request from './config'

export function addBaiduPlan (params) {
  return request.post('miniprogram/baiduPlan', params)
}

export function getBaiduPlanList (params) {
    return request.get('/miniprogram/baiduPlan/selectAll', {params})
}

// ! 查询落地页列表数据

export function getLandingPageList (params) {
  return request.get('/miniprogram/baiduPlugin/selectAll', {params})
}

// ! 删除落地页素材

export function delLandingPage(id) {
  return request.delete(`/miniprogram/baiduPlugin/${id}`)
}

// ! 删除百度推广计划

export function delProjectItem(ids){
  return request.delete(`/miniprogram/baiduPlan/${ids}`)
}

// ! 设置默认推广页

export function setDefaultLandingPage(id) {
  return request.put(`/miniprogram/baiduPlugin/setDefault/${id}`)
}
// ! 添加备注

export function addLandingPageRemark(id,remark) {
  return request.put(`/miniprogram/baiduPlan/${id}?remark=${remark}`,)
}

// ! 获取模板数据列表 

export function getTemplateList(params) {
  return request.get('/miniprogram/baiduPlan/selectTemplate',{params})
}
// !获取模块对应计划数据
export function getTemplateDetail(templateId) {
  return request.get(`/miniprogram/baiduPlan/selectPlanDetail/${templateId}`)
}

// ! 删除模块数据

export function delTemplateList(ids){
   return request.delete(`/miniprogram/baiduPlan/deleteTemplate/${ids}`)
}

// ! 获取百度账号列表 
export function getAllBaiduAccountList (params) {
  return request.get('/miniprogram/baiduPlan/selectChannel',{params})
}

// ! 修改百度账号备注

export function editBaiduAccountRemark(id,{remark}){
  return request.put(`/miniprogram/baiduPlan/updateBaiDuRemark/${id}?remark=${remark}`)
}